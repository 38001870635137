import ParentCompo from "./components/parentCompo/ParentCompo";
import "../src/assets/scss/style.scss";
import MediaProvider from "./context/MediaContext";

function App() {
  return (
    <MediaProvider>
      <ParentCompo />
    </MediaProvider>
  );
}

export default App;
