/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext } from "react";
import styles from "./Home.module.scss";
import Asset from "../Asset/Asset";
import LogoIcon from "../../assets/images/swan-logo-icon.svg";
import CustomButton from "../customButton/CustomButton";
import { COMMUNICATION_TYPE, CONTENT_TYPE, EVENT_TYPE, MESSAGE, PRIVACY_POLICY_LINK } from "../../constants/Constants";
import Header from "../header/Header";
import { PropsPassContext } from "../../context/UserDetails";
import { MEDIA_TYPES, MediaContext } from "../../context/MediaContext";

const Home = () => {
  const { setContentType, handleMessage, email } = useContext(PropsPassContext);
  const { media } = useContext(MediaContext);
  const handleNext = useCallback(() => {
    if (media === MEDIA_TYPES.MOBILE) {
      handleMessage?.(EVENT_TYPE.REQUEST, COMMUNICATION_TYPE.SENT, MESSAGE.REDIRECT);
    } else {
      setContentType(CONTENT_TYPE.OR_CODE);
    }
  }, [media]);
  return (
    <div className={`${styles.swanHome} swan-flex`}>
      <div className={`${styles.swanHome__header}`}>
        <Header logoCircle logoText="- AI Fitting Room" />
      </div>

      <div className={`${styles.swanHome__left} swan-flex-column`}>
        <div className={`${styles.swanHome__headerMobile}`}>
          <Header />
        </div>
        <div className={`${styles.swanHome__infoMobile}`}>
          <h2>AI Fitting Room</h2>
          <p>Setup once. See & size clothes virtually anywhere you see Swan’s logo.</p>
        </div>
        <div className={`${styles.swanHome__animationMobile}`}>
          <Asset />
        </div>
        <div className={`${styles.swanHome__info} swan-flex-column swan-align-center swan-justify-center`}>
          <img src={LogoIcon} alt="swan" />
          <p>See & size clothes virtually anywhere you see Swan’s logo.</p>
          <div className={`${styles.swanHome__btn}`}>
            <CustomButton className="button blue full rounded" buttonText="Start" buttonFunc={handleNext} />
            {!email && (
              <p>
                Already have an account?{" "}
                <span role="button" onClick={() => setContentType(CONTENT_TYPE.LOGIN)}>
                  Log in
                </span>
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.swanHome__footer}`}>
          <h5>PRIVACY</h5>
          <p>
            Swan encrypts your data and keeps it secure. By continuing, you’re agreeing to our{" "}
            <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
              privacy policy
            </a>
            .
          </p>
          <div className={`${styles.swanHome__footerBtn}`}>
            {!email && (
              <div className={`${styles.swanHome__footerBtnCol}`}>
                <CustomButton className="button outline full  rounded" buttonText="Login" buttonFunc={() => setContentType(CONTENT_TYPE.LOGIN)} />
              </div>
            )}
            <div className={`${styles.swanHome__footerBtnCol}`}>
              <CustomButton className="button blue full rounded" buttonText="Start" buttonFunc={handleNext} />
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.swanHome__right} swan-flex-column`}>
        <Asset />
      </div>
    </div>
  );
};

export default Home;
