import { REACT_APP_FITVIEW_URL } from "../constants/Constants";
import { v4 as uuidv4 } from "uuid";

export const isValidEmail = (email) => {
  const checkEmailValidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return checkEmailValidation.test(email);
};

export const makeMobileUrl = ({ productHandle, shopDomain, shopify_redirect_url }) => {
  return `${REACT_APP_FITVIEW_URL}/?product_name=${productHandle}&shopify_redirect=${shopify_redirect_url}&shop_domain=${shopDomain}`;
};

export function generateUuid() {
  return uuidv4();
}
