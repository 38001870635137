export const CONTENT_TYPE = {
  LANDING: "LANDING",
  OR_CODE: "QR_CODE",
  LOGIN: "LOGIN",
  NONE: "NONE",
};

export const { REACT_APP_BASE_URL, REACT_APP_FITVIEW_URL, REACT_APP_WEBSOCKET_BASE_URL } = process.env;

export const API_END_POINTS = {
  GET_USER_DETAIL: "/user",
  REGISTER_USER: "/auth/register",
  VERIFY_USER: "/auth/verify",
};

export const COMMUNICATION_TYPE = {
  RECEIVE: "RECEIVE",
  SENT: "SENT",
};

export const MESSAGE = {
  REDIRECT: "REDIRECT",
  CLOSE: "CLOSE",
};
export const EVENT_TYPE = { REQUEST: "REQUEST", INFO: "INFO", DETAILS: "DETAILS" };
export const PRIVACY_POLICY_LINK = "https://www.swanvision.ai/privacy";
