/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";

export const MEDIA_TYPES = {
  DESKTOP: "DESKTOP",
  TAB: "TAB",
  MOBILE: "MOBILE",
};
export const MediaContext = React.createContext();

function MediaProvider({ children }) {
  const [size, setSize] = useState([window?.innerWidth, window?.innerHeight]);
  const [clearInputs, setClearInputs] = useState(false);

  const updateSize = () => {
    setSize([window?.innerWidth, window?.innerHeight]);
  };
  useEffect(() => {
    updateSize();
  }, []);
  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [updateSize]);
  let media = MEDIA_TYPES.DESKTOP;
  if (size[0] > 768 && size[0] < 1024) {
    media = MEDIA_TYPES.TAB;
  }
  if (size[0] < 768) {
    media = MEDIA_TYPES.MOBILE;
  }
  const mediaDetails = useMemo(
    () => ({
      size,
      setSize,
      clearInputs,
      setClearInputs,
      media,
    }),
    [size, clearInputs, media]
  );

  return <MediaContext.Provider value={mediaDetails}>{children}</MediaContext.Provider>;
}

export default MediaProvider;
