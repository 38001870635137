import React, { useContext, useRef, useState } from "react";
import CustomButton from "../customButton/CustomButton";
import styles from "./Login.module.scss";
import Header from "../header/Header";
import check from "../../assets/images/check-blue.svg";
import { AuthService } from "../../services/auth.service";
import { isValidEmail } from "../../utils/Utils";
import { COMMUNICATION_TYPE, CONTENT_TYPE, EVENT_TYPE, REACT_APP_WEBSOCKET_BASE_URL } from "../../constants/Constants";
import { PropsPassContext } from "../../context/UserDetails";
import useWebsocket from "../../customHook/useWebsocket";
const authService = new AuthService();
const Login = () => {
  const { setContentType, redirectUrl, handleMessage } = useContext(PropsPassContext);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { handleAuthSocket } = useWebsocket(`${REACT_APP_WEBSOCKET_BASE_URL}/auth`);
  const detailsRef = useRef({ scanId: "", email: "" });
  const onSuccess = (data) => {
    handleMessage?.(EVENT_TYPE.DETAILS, COMMUNICATION_TYPE.SENT, detailsRef.current);
    setContentType(CONTENT_TYPE.LANDING);
  };

  const handleLogin = async () => {
    if (!isValidEmail(email.trim())) {
      setError(true);
      setShowMessage("Please enter a valid email.");
      return;
    }
    setError(false);
    setLoading(true);
    setShowMessage("");
    try {
      const response = await authService.getUserInfo(email);
      let url = `${redirectUrl}?email=${encodeURIComponent(email)}&auth_type=login`;
      if (response?.data?.latest_scan_id) {
        url += `&scan_id=${response?.data?.latest_scan_id}`;
      }
      await authService.registerUser(email, url);
      setSuccess(true);
      detailsRef.current = { email, scanId: response?.data?.latest_scan_id };
      handleAuthSocket({ detailObj: detailsRef.current, onSuccess });
    } catch (error) {
      setError(true);
      setShowMessage(error?.response?.data?.message || error?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.swanLogin} swan-flex-column`}>
      <div className={`${styles.swanLogin__header}`}>
        <Header logoCircle />
      </div>
      <>
        {success ? (
          <div className={`${styles.swanLogin__info} swan-justify-center swan-flex swan-align-center`}>
            <div className={`${styles.swanLogin__message}`}>
              {!loading && !error && (
                <>
                  <img src={check} alt="check" />
                  Perfect! Check your inbox now
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className={`${styles.swanLogin__info} swan-justify-center swan-flex swan-align-center`}>
              <div className={`${styles.swanLogin__wrapper}`}>
                <p>👋🏻</p>
                <h1>Welcome back!</h1>
                <input
                  className={`${styles.swanLogin__input}`}
                  placeholder="youremail@example.com"
                  value={email}
                  onChange={(e) => {
                    setError(false);
                    setEmail(e.target.value);
                  }}
                />
                <div className={`${styles.swanLogin__error}`}>{!loading && error && showMessage}</div>
              </div>
            </div>
            <div className={`${styles.swanLogin__footer} swan-flex swan-justify-center`}>
              <CustomButton className="button rounded" buttonText="Back" disabled={loading} buttonFunc={() => setContentType(CONTENT_TYPE.LANDING)} />
              <CustomButton
                className="button blue rounded"
                loading={loading}
                buttonText="Load my account"
                disabled={loading}
                buttonFunc={handleLogin}
              />
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default Login;
