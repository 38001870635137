import React from "react";
import logoWhite from '../../assets/images/logo-white.svg';
import LogoCircle from '../../assets/images/logo-circle.svg';
const Logo = ({logoCircle,logoText}) => {
  return <>
  {logoCircle ? 
  <span className="swan-logo">
    <img src={LogoCircle} alt="swan" title="swan" />
    <span className="swan-logo__text">
        Swan <span>{logoText}</span>
    </span>
    
    </span>
  :
    <img src={logoWhite} alt="swan" title="swan" />
  }

  </>;
};

export default Logo;
