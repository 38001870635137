/* eslint-disable import/no-extraneous-dependencies */
import { Canvas } from "@react-three/fiber";
import React, { Suspense, lazy } from "react";

const Scene = lazy(() => import("./Scene/Scene"));

export default function Asset() {
  return (
    <Canvas
      camera={{
        position: [0, 0, 0.5],
      }}
      dpr={[1, 2]}
    >
      <Suspense>
        <Scene />
      </Suspense>
    </Canvas>
  );
}
