/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import styles from "./QRcode.module.scss";
import Header from "../header/Header";
import { PropsPassContext } from "../../context/UserDetails";
import { generateUuid } from "../../utils/Utils";
import useWebsocket from "../../customHook/useWebsocket";
import { COMMUNICATION_TYPE, CONTENT_TYPE, EVENT_TYPE, MESSAGE, REACT_APP_WEBSOCKET_BASE_URL } from "../../constants/Constants";
const qrCode = generateUuid();
const QRcode = () => {
  const { mobileUrl, handleMessage, setContentType, setMobileUrl } = useContext(PropsPassContext);
  const { handleQRSocket } = useWebsocket(`${REACT_APP_WEBSOCKET_BASE_URL}/qrsync?qrCodeId=${qrCode}`);

  const handleSuccess = (data) => {
    handleMessage?.(EVENT_TYPE.REQUEST, COMMUNICATION_TYPE.SENT, MESSAGE.CLOSE);
    setContentType(CONTENT_TYPE.LANDING);
  };
  const handleSocket = () => {
    handleQRSocket({ onSuccess: handleSuccess });
  };
  useEffect(() => {
    setMobileUrl(`${mobileUrl}&qr_code=${qrCode}`);
    handleSocket();
  }, []);

  return (
    <div className={`${styles.swanQr} swan-flex-column`}>
      <div className={`${styles.swanQr__header}`}>
        <Header logoCircle />
      </div>
      <div className={`${styles.swanQr__info} swan-flex swan-align-center swan-justify-center`}>
        <div className={`${styles.swanQr__wrapper}`}>
          <h1>Scan with your phone</h1>
          <div className={`${styles.swanQr__scan}`}>
            <QRCodeSVG value={mobileUrl} size={192} />
          </div>
          <p>Create an account within two mins on your phone & enjoy an AI Fitting Room experience anywhere.</p>
        </div>
      </div>
    </div>
  );
};

export default QRcode;
