import React from "react";


const CustomButton = ({ buttonFunc, className, buttonText, loading = false, disabled }) => {
  return (
    <>
      <button disabled={disabled} type="button" onClick={buttonFunc} className={className}>
        {buttonText}
        {loading && disabled && <span className="button__loader"></span>}
      </button>
    </>
  );
};

export default CustomButton;
