import React, { useContext } from "react";
import styles from "./Header.module.scss";
import Logo from "../logo/Logo";
import Cross from "../../assets/icon/cross";
import { PropsPassContext } from "../../context/UserDetails";
import { COMMUNICATION_TYPE, CONTENT_TYPE, EVENT_TYPE, MESSAGE } from "../../constants/Constants";

const Header = ({ logoCircle, noCross, logoText }) => {
  const { handleMessage, setContentType } = useContext(PropsPassContext);
  return (
    <div className={` ${styles.swanHeader} swan-align-center swan-flex  swan-justify-between`}>
      <Logo logoCircle={logoCircle} logoText={logoText} />
      {noCross ? (
        <></>
      ) : (
        <>
          {" "}
          <span
            className={`header-cross ${styles.swanHeader__cross} ${logoCircle ? styles.blackCross : ""} `}
            role="button"
            onClick={() => {
              handleMessage?.(EVENT_TYPE.REQUEST, COMMUNICATION_TYPE.SENT, MESSAGE.CLOSE);
              setContentType(CONTENT_TYPE.LANDING);
            }}
          >
            <Cross />
          </span>
        </>
      )}
    </div>
  );
};

export default Header;
