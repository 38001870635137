import { useEffect, useRef } from "react";

const useWebsocket = (url) => {
  const socketRef = useRef();
  const disconnectSocket = () => {
    socketRef?.current?.close();
  };
  const handleAuthSocket = ({ onSuccess, onFailed, detailObj = {} }) => {
    disconnectSocket();
    socketRef.current = new WebSocket(url);
    socketRef.current.onopen = () => {
      socketRef.current.send(JSON.stringify(detailObj));
    };
    socketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      onSuccess?.(data);
    };
    socketRef.current.onerror = (event) => {
      onFailed?.();
    };
    socketRef.current.onclose = () => {
      console.log("webSocket closed");
    };
  };

  const handleQRSocket = ({ onSuccess, onFailed }) => {
    disconnectSocket();
    socketRef.current = new WebSocket(url);
    socketRef.current.onopen = () => {};
    socketRef.current.onerror = (event) => {
      onFailed?.();
    };
    socketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      onSuccess?.(data);
    };
    socketRef.current.onclose = () => {
      console.log("webSocket closed");
    };
  };

  useEffect(() => {
    return () => {
      disconnectSocket();
    };
  }, [url]);
  return { handleAuthSocket, handleQRSocket };
};

export default useWebsocket;
